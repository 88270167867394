<template>
  <div>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Riwayat <b>Pengobatan Dokter</b> {{ clinic_name }}
      </div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <div class="row align-items-center justify-content-between">
              <div class="col-md-6 col-12">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <template #prepend>
                      <b-button
                        squared
                        @click="resetFilterPeriode"
                        variant="danger">Reset</b-button>
                      <b-button
                        squared
                        @click="filterByPeriode"
                        variant="success">Cari</b-button>
                    </template>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Tanggal Awal"
                      autocomplete="off"
                      readonly></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"></b-form-datepicker>
                    </b-input-group-append>
                    <b-button
                      squared
                      variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Tanggal Akhir"
                      autocomplete="off"
                      readonly></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <template #prepend>
                          <b-button
                            squared
                            @click="resetFilterPeriode"
                            variant="danger">Reset</b-button>
                          <b-button
                            squared
                            @click="filterByPeriode"
                            variant="success">Cari</b-button>
                        </template>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button
                          squared
                          variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
              <div class="col-md-3 col-12 mt-md-0 mt-n4">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Dokter"
                    @keyup="filterByName"></b-form-input>
                </b-input-group>
              </div>
            </div>

            <b-table
              striped
              hover
              responsive
              class="mt-3"
              :items="items"
              :fields="fields">
              <template #cell(photo)='data'>
                <div class="d-flex justify-content-center align-items-center image-container">
                  <div
                    class=""
                    style='height: 50px; width: 50px; position: relative'>
                    <img
                      class="image"
                      :src="data.item.photo">
                  </div>
                </div>
              </template>
              <template #cell(actions)="data">
                <div class="d-flex">
                  <b-button
                    size="sm"
                    class="mr-1 btn-info"
                    v-b-tooltip.hover
                    title="Perjanjian"
                    @click="$router.push({ path: '/doctor/doctor-appointment/' + data.item.id })"><i class="fas fa-tasks px-0"></i></b-button>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              @page-click="pageOnClick"
              class="mt-4"></b-pagination>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>

import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debouncer } from "@/core/modules/Helper.js"
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {

  components: {
    Card,
  },

  data() {
    return {
      // filter
      filter: {
        name: "",
        start_date: "",
        end_date: "",
      },
      // pagination
      perPage: 5,
      currentPage: 1,
      lastCurrentPage: 1,
      totalRows: 0,
      // table fields
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "name",
          label: "Nama Dokter",
          sortable: true,
        },
        {
          key: "polyclinic_name",
          label: "Poliklinik",
          sortable: true,
        },
        {
          key: "treatment_count",
          label: "Jumlah Penanganan",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // table items
      items: [],
      clinic_name: window.configuration == null ? "Medhop" : window.configuration.clinic_name
    }
  },

  methods: {

    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.lastCurrentPage = page;
      await this.pagination();
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter);
      this.pagination();
    },

    filterByPeriode() {
      if (this.filter.name != "" || this.filter.start_date != '' || this.filter.end_date != '') {
        this.currentPage = 1
      } else {
        this.currentPage = this.lastCurrentPage
      }
      this.pagination();
    },

    filterByName() {
      debouncer(() => {
        if (this.filter.name != "" || this.filter.start_date != '' || this.filter.end_date != '') {
          this.currentPage = 1
        } else {
          this.currentPage = this.lastCurrentPage
        }
        this.pagination();
      }, 500)
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        `doctors/get-treatment-history`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dokter" },
      { title: "Riwayat Pengobatan" },
    ]);
    this.pagination();
  },

};
</script>
